import { render, staticRenderFns } from "./AdministradorContratoComponent.vue?vue&type=template&id=76bf530c"
import script from "./AdministradorContratoComponent.vue?vue&type=script&lang=js"
export * from "./AdministradorContratoComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports